<template>
  <context-menu
    icon="share"
    :section="section"
    :attach-to-bottom="false"
    :use-redesign="useRedesign"
  >
    <template v-slot="slotScope">
        <share-with-buttons
          :class="shareWithButtonClasses"
          v-on-clickaway="slotScope.close"
          :share-header="title"
          :use-redesign="useRedesign"
          :share-buttons="buttons"
          :share-link="link"
          :share-description="shareDescription"
          :share-title="shareTitle"
          :som-today-environment="somTodayEnvironment"
          @close="slotScope.close"
          @clickIcon="slotScope.close"
        />
    </template>
  </context-menu>
</template>

<script>
  import ShareWithButtons from "./ShareWithButtons";
  import {mixin as clickaway} from 'vue-clickaway';
  import ContextMenu from "../ContextMenu";

  export default {
    mixins: [ clickaway ],

    components: { ContextMenu, ShareWithButtons },

    props: {
      section: String,
      link: String,
      buttons: Array,
      shareTitle: String,
      shareDescription: String,
      title: String,
      somTodayEnvironment: String,
      useRedesign: {
        type: Boolean,
        default: false
      }    
    },

    computed: {
      shareWithButtonClasses() {
        if(this.useRedesign) {
          return 'whitespace-nowrap'
        }
        return 'context-menu__section'
      }      
    }
  }
</script>
