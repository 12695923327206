<template>
  <span>
    <audio ref="audio" v-if="assets.length > 0" @canplaythrough="enable" @ended="ended">
      <source v-for='asset in assets' :src="asset.source" :type="asset.type">
    </audio>
    <button-icon-only v-if="redesign" :icon-class="playing ? 'animate-pulse-fast' : ''" size="small" :icon="playing ? 'pause' : 'play'" @click="togglePlay" label="" :disabled="disabled"></button-icon-only>
    <span v-else class="button button--secondary button--circle button--inline-audio" :class="playing ? 'button--inline-audio-playing' : ''" @click="togglePlay">
      <i :class="playing ? 'icon-pause' : 'icon-play'"></i>
    </span>
  </span>
</template>

<script>

import ButtonIconOnly from '../../redesign/ButtonIconOnly'
import VmsStore from './VmsStore'
import { AUDIO_ENDED_EVENT, AUDIO_PAUSE_EVENT, AUDIO_PLAY_EVENT, EventBus } from "../../common/EventBus";

export default {
  components: {
    ButtonIconOnly
  },
  props: {
    vmsId: Number,
    redesign: Boolean
  },
  data () {
    return {
      assets: [],
      disabled: true,
      playing: false,
      store: new VmsStore()
    }
  },
  async created () {
    this.assets = await this.store.getAudioAssets(this.vmsId)
  },
  methods: {
    enable () {
      this.disabled = false
    },
    ended () {
      if (this.$refs.audio.ended) {
        EventBus.$emit(AUDIO_ENDED_EVENT, this.vmsId)
      }
      this.$refs.audio.currentTime = 0
      this.playing = false
    },
    togglePlay () {
      if (!this.$refs.audio instanceof Audio || this.disabled) {
        return
      }

      if (this.$refs.audio.paused) {
        EventBus.$emit(AUDIO_PLAY_EVENT, this.vmsId)
        this.$refs.audio.play()
        this.playing = true
      } else {
        EventBus.$emit(AUDIO_PAUSE_EVENT, this.vmsId)
        this.$refs.audio.pause()
        this.ended()
      }
    }
  }
}
</script>
