import { EventBus, PARTIAL_CONTENT_WILL_UPDATE_EVENT } from '../common/EventBus'
import * as Sentry from "@sentry/vue";

const READSPEAKER_START = ' RSPEAK_START '
const READSPEAKER_SECTION_CLASS = 'js-readspeaker-section'

EventBus.$on(PARTIAL_CONTENT_WILL_UPDATE_EVENT, (element) => {
  if (!window.rspkr.ui) {
    return
  }
  const activePlayer = window.rspkr.ui.getActivePlayer()
  if (!activePlayer) {
    return
  }
  const playerContainer = activePlayer.getContainer()
  if (element.contains(playerContainer)) {
    activePlayer.close()
  }
})

const getParentScrollContainer = (element) => {
  let parentNode = element.parentNode
  let computedStyle = getComputedStyle(parentNode)
  while (parentNode !== document.body && computedStyle.overflowY !== 'scroll') {
    parentNode = parentNode.parentNode
    computedStyle = getComputedStyle(parentNode)
  }
  return parentNode
}

export default class ReadSpeaker {
  constructor (element) {
    this._elementId = element.id
    this._readspeakerId = element.id.replace('readspeaker_button_', '')

    this._readElement = document.getElementById(this._readspeakerId)

    if (null === this._readElement) {
      Sentry.captureException(
        new Error('Readspeaker button cannot find element with readspeaker-id ' + this._readspeakerId)
      )
      element.remove()
      return
    }

    this._readFromScroll = !this._readElement.classList.contains('readspeaker-read-all')

    if (this._readFromScroll) {
      // detect the top position of the scrollable container
      this._scrollTopDetection = 50
      const parentNode = getParentScrollContainer(document.getElementById(this._readspeakerId))
      if (parentNode !== document.body) {
        this._scrollTopDetection = parentNode.getBoundingClientRect().top
      }
      this._startElement = document.createComment(READSPEAKER_START)
    }

    this._connectReadspeaker();
  }

  _connectReadspeaker () {
    if (window.ReadSpeaker) {
      window.ReadSpeaker.q(() => {
        setTimeout(() => {
          window.rspkr.ui.addClickEvents()
          document.getElementById(this._elementId).addEventListener('mousedown', () => {
            this._placeStartMarker()
          })
        }, 0)
      })
      return;
    }

    setTimeout(() => this._connectReadspeaker, 100);
  }

  _placeStartMarker () {
    if (!this._readFromScroll) {
      return
    }

    const treeWalker = document.createTreeWalker(document.getElementById(this._readspeakerId), NodeFilter.SHOW_COMMENT, null)
    let comment = treeWalker.nextNode()
    while (comment) {
      if (comment.data === READSPEAKER_START) {
        comment.remove()
      }
      comment = treeWalker.nextNode()
    }

    let top = this._scrollTopDetection
    const headerStuck = this._readElement.querySelector('.js-header-stuck')
    if (headerStuck) {
      top += headerStuck.getBoundingClientRect().height
    }
    const firstVisibleElement = this._getFirstVisibleElement(this._readElement, top)
    if (firstVisibleElement) {
      firstVisibleElement.parentNode.insertBefore(this._startElement, firstVisibleElement)
    }
  }

  _getChildren (element, recursive) {
    if (element.classList.contains(READSPEAKER_SECTION_CLASS)) {
      // an element with the js-readspeaker-section class is a single section and therefor has no readspeaker-children
      return []
    }

    const allowedTags = ['HEADER', 'DIV', 'SECTION', 'H2', 'H3', 'H4', 'P', 'SPAN', 'FORM']

    const children = Array.prototype.slice.call(element.children)
    const filteredChildren = []
    for (const child of children) {
      if (allowedTags.indexOf(child.tagName) === -1) {
        continue
      }
      const classList = child.classList
      if (classList.contains('rs_skip') || classList.contains('js_readspeaker') || classList.contains('js-sticky-trigger') || classList.contains('js-header-stuck')) {
        continue
      }
      filteredChildren.push(child)
      if (recursive) {
        const grandChildren = this._getChildren(child, true)
        if (grandChildren.length > 0) {
          filteredChildren.push(...grandChildren)
        }
      }
    }
    return filteredChildren
  }

  _getFirstVisibleElement (element, top) {
    const children = this._getChildren(element, true)
    for (const element of children) {
      const rect = element.getBoundingClientRect()
      if (rect.top >= top) {
        const elementChildren = this._getChildren(element, false)
        if (elementChildren.length === 0) {
          return element
        }
      }
    }
    return null
  }

  static fixDuplicatePreserve (container) {
    container.querySelectorAll('.rs_preserve .rs_preserve').forEach((element) => {
      element.className = element.className.replace('rs_preserve', '')
    })
  }
}
