var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("context-menu", {
    attrs: {
      icon: "share",
      section: _vm.section,
      "attach-to-bottom": false,
      "use-redesign": _vm.useRedesign,
    },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function (slotScope) {
          return [
            _c("share-with-buttons", {
              directives: [
                {
                  name: "on-clickaway",
                  rawName: "v-on-clickaway",
                  value: slotScope.close,
                  expression: "slotScope.close",
                },
              ],
              class: _vm.shareWithButtonClasses,
              attrs: {
                "share-header": _vm.title,
                "use-redesign": _vm.useRedesign,
                "share-buttons": _vm.buttons,
                "share-link": _vm.link,
                "share-description": _vm.shareDescription,
                "share-title": _vm.shareTitle,
                "som-today-environment": _vm.somTodayEnvironment,
              },
              on: { close: slotScope.close, clickIcon: slotScope.close },
            }),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }