'use strict';

const _hash = str => {
  return String(str.split('').reduce((prevHash, currVal) =>
    (((prevHash << 5) - prevHash) + currVal.charCodeAt(0))|0, 0));
}

const _getBound = element => {
  if (element.bound) {
    return element.bound.split('|')
  }
  return []
}

const _addBound = (bindings, name) => {
  bindings.push(name)
  return bindings.join('|')
}

export default (container, selector, className, options) => {
  const elements = container.querySelectorAll(selector)
  const bindKey = _hash(selector)

  elements.forEach(element => {
    const bindings = _getBound(element)

    if (bindings.indexOf(bindKey) >= 0) {
      //already bound
      return
    }

    new className(element, options)
    element.bound = _addBound(bindings, bindKey)
  })
}
