<template>
  <component :is="tag"
             class="font-bold text-sm inline-flex items-center whitespace-nowrap"
             :class="classNames"
             :data-test="'button-' + type"
             :href="href"
             :disabled="disabled"
             :target="target"
             @click="$emit('click', $event)"
  >
    <span v-if="icon && iconPosition === 'left'" class="fill-current inline-block w-3 h-3" :class="iconSpacingClassName" v-html="iconContent"></span>
    <slot v-if="$slots.default"/>
    <template v-else>{{ label }}</template>
    <span v-if="icon && iconPosition === 'right'" class="fill-current inline-block w-3 h-3" :class="iconSpacingClassName" v-html="iconContent"></span>
  </component>
</template>

<script>

export const CustomButtonProps = {
  label: String,
  type: {
    type: String,
    default: 'primary'
  },
  href: String,
  disabled: Boolean,
  icon: String,
  target: {
    type: String,
    default: '_self'
  },
  iconSpacing: {
    type: String,
    default: 'default'
  },
  iconPosition: {
    default: 'left',
    type: String
  },
  onClick: {
    type: String,
    default: ''
  }
}

const iconSpacingClasses = {
  'left': {
    'small': 'mr-1.5',
    'default': 'mr-3'
  },
  'right': {
    'small': 'ml-1.5',
    'default': 'ml-3'
  }
};

export default {
  props: CustomButtonProps,
  data() {
    return {
      iconContent: this.icon ? require('@Icons/' + this.icon + '.svg') : ''
    }
  },
  computed: {
    tag () {
      return this.href ? 'a' : 'button'
    },
    classNames () {
      if (this.type === 'primary') {
        return 'rounded-lg py-2 px-8 focus:outline-none bg-blue-dark hover:bg-blue focus:bg-blue active:bg-blue-dark disabled:bg-gray-400 disabled:cursor-auto text-white disabled:text-gray-575'
      }
      if (this.type === 'secondary') {
        return 'rounded-lg py-2 px-8 focus:outline-none bg-gray-600 hover:bg-gray-575 focus:bg-gray-575 active:bg-gray-600 disabled:bg-gray-400 disabled:cursor-auto text-white disabled:text-gray-575'
      }
      if (this.type === 'tertiary') {
        return 'text-black hover:text-blue focus:text-blue disabled:text-gray-400 disabled:cursor-auto'
      }
      if (this.type === 'teacher') {
        return 'rounded-lg py-2 px-8 focus:outline-none bg-red hover:bg-red-light focus:bg-red-light active:bg-red disabled:bg-gray-400 disabled:cursor-auto text-white disabled:text-gray-575'
      }
      return ''
    },
    iconSpacingClassName () {
      return iconSpacingClasses[this.iconPosition][this.iconSpacing]
    }
  }
}
</script>
