var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    _vm.tag,
    {
      tag: "component",
      staticClass:
        "font-bold text-sm inline-flex items-center whitespace-nowrap",
      class: _vm.classNames,
      attrs: {
        "data-test": "button-" + _vm.type,
        href: _vm.href,
        disabled: _vm.disabled,
        target: _vm.target,
      },
      on: {
        click: function ($event) {
          return _vm.$emit("click", $event)
        },
      },
    },
    [
      _vm.icon && _vm.iconPosition === "left"
        ? _c("span", {
            staticClass: "fill-current inline-block w-3 h-3",
            class: _vm.iconSpacingClassName,
            domProps: { innerHTML: _vm._s(_vm.iconContent) },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.$slots.default ? _vm._t("default") : [_vm._v(_vm._s(_vm.label))],
      _vm._v(" "),
      _vm.icon && _vm.iconPosition === "right"
        ? _c("span", {
            staticClass: "fill-current inline-block w-3 h-3",
            class: _vm.iconSpacingClassName,
            domProps: { innerHTML: _vm._s(_vm.iconContent) },
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }