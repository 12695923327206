import {
  EventBus,
  VIDEO_ENDED_EVENT,
  VIDEO_PAUSE_EVENT,
  VIDEO_PLAY_EVENT,
  VIDEO_RESUME_EVENT
} from '../common/EventBus';

import {
  MEDIA_EVENT_MEDIA_TYPE_JOJOSCHOOL
} from '../thas/ThasService';

export default class JojoVideo {
  static  get origin () { return 'https://leren.jojoschool.nl'; }

  #videoId = null;
  #hasStarted = false;

  constructor (el) {
    this.videoId = el.getAttribute('data-jojo-id');

    if (this.videoId !== null) {
      window.addEventListener('message', this.handleEvent);
    }
  }

  handleEvent (event) {
    if (event.origin !== JojoVideo.origin) { return; }

    switch (event.data.type) {
      case 'play':
        EventBus.$emit(this.hasStarted ? VIDEO_RESUME_EVENT : VIDEO_PLAY_EVENT, this.videoId, MEDIA_EVENT_MEDIA_TYPE_JOJOSCHOOL);
        this.hasStarted = true;
        break;
      case 'pause':
        EventBus.$emit(VIDEO_PAUSE_EVENT, this.videoId, MEDIA_EVENT_MEDIA_TYPE_JOJOSCHOOL);
        break;
      case 'ended':
        EventBus.$emit(VIDEO_ENDED_EVENT, this.videoId, MEDIA_EVENT_MEDIA_TYPE_JOJOSCHOOL);
        this.hasStarted = false;
        break;
    }
  }
}
