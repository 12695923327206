const _getUuidElement = (element, name) => {
  let uuidElement = element.querySelector('input[name="' + name + '"]')

  if (!uuidElement) {
    uuidElement = document.createElement('input')
    uuidElement.type = 'hidden'
    uuidElement.name = name
    element.appendChild(uuidElement)
  }

  return uuidElement
}

const _uuidv4 = () => {
  return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, c =>
    (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
  )
}

export default class UuidLoggerForm {
  constructor (element) {
    element.addEventListener('submit', event => {
      _getUuidElement(element, 'loggerUuid').value = _uuidv4()
    })

    element.querySelectorAll('button[type="submit"]').forEach(submitButton => {
      submitButton.addEventListener('click', event => {
        _getUuidElement(element, 'loggerSubmitUuid').value = _uuidv4()
      })
    })
  }
}
