'use strict'

import request from '../../util/request';

const VMS_MEDIACLIP_API_URI = 'https://thiememeulenhoff.bbvms.com/p/default/c/{vmsId}.json'

const ASSET_MEDIA_TYPES = {
  'MP3': 'audio/mpeg',
  'AAC': 'audio/mp4'
}

export default class VmsStore {
  constructor () {
  }

  async getAudioAssets(vmsId) {
    try {
      const response = await request(VMS_MEDIACLIP_API_URI.replace('{vmsId}', vmsId))
      return this.audioAssets(response.data.clipData.assets, response.data.publicationData.defaultMediaAssetPath)
    } catch (e) {
      throw new Error(e.response.data);
    }
  }

  audioAssets(assets, uri) {
    return assets.sort((a, b) => {
      if ('MP3' === a.mediatype && 'MP3' !== b.mediatype) {
        return 1
      }
      return Number(a.bandwidth) > Number(b.bandwidth) ? -1 : 1
    }).map(asset => {
      return {
        source: uri + asset.src,
        type: ASSET_MEDIA_TYPES.hasOwnProperty(asset.mediatype) ? ASSET_MEDIA_TYPES[asset.mediatype] : null
      }
    })
  }
}
