'use strict'

import request from '../../util/request'
import Routing from '../../util/routing'

let allTerms = null
let termsPromise = null

export class TermCannotBeFoundError extends Error {}

const _getReferenceData = reference => {
  const data = reference.split('#')

  if (2 === data.length) {
    return {
      groupId: data[0],
      termId: data[1]
    }
  }

  return {
    groupId: null,
    termId: data[0]
  }
}

const _matchTerm = (term, reference) => {
  return term.termId === reference.termId && (null === reference.groupId || term.groupId === reference.groupId)
}

export class TermService {
  static async getTerm (reference) {
    if (!msp.streamCode) {
      throw new TermCannotBeFoundError(`Term content not found, no streamCode available`)
    }

    if (termsPromise === null) {
      const url = Routing.generate('content_terms', { streamCode: msp.streamCode }) + '?revision=' + msp.streamRevision
      termsPromise = request(url)
    }

    if (allTerms === null) {
      const response = await termsPromise
      allTerms = {};
      for (const term of response.data) {
        const data = _getReferenceData(term.id)
        term.groupId = data.groupId
        term.termId = data.termId
        allTerms[term.id] = term;
      }
    }

    const altReference = TermService.cleanReference(reference)

    let foundTerm = TermService.getByReference(reference)
    if (null === foundTerm && null !== altReference) {
      foundTerm = TermService.getByReference(altReference)
    }

    if (null === foundTerm) {
      const altMessage = null === altReference ? '' : ` or "${altReference}"`;
      throw new TermCannotBeFoundError(`Term content not found for reference "${reference}"${altMessage}`)
    }

    return foundTerm;
  }

  static cleanReference(reference) {
    if (reference.includes('thip.')) {
      return reference.replace('thip.', '');
    }
    return null;
  }

  static getByReference(reference) {
    if (allTerms.hasOwnProperty(reference)) {
      return allTerms[reference]
    }

    // Find by termId and groupId
    const referenceData = _getReferenceData(reference)
    const foundTerm = Object.values(allTerms).find(term => {
      if (_matchTerm(term, referenceData)) {
        return term;
      }
    })

    return foundTerm ?? null;
  }
}
