var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    [
      _vm.assets.length > 0
        ? _c(
            "audio",
            {
              ref: "audio",
              on: { canplaythrough: _vm.enable, ended: _vm.ended },
            },
            _vm._l(_vm.assets, function (asset) {
              return _c("source", {
                attrs: { src: asset.source, type: asset.type },
              })
            }),
            0
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.redesign
        ? _c("button-icon-only", {
            attrs: {
              "icon-class": _vm.playing ? "animate-pulse-fast" : "",
              size: "small",
              icon: _vm.playing ? "pause" : "play",
              label: "",
              disabled: _vm.disabled,
            },
            on: { click: _vm.togglePlay },
          })
        : _c(
            "span",
            {
              staticClass:
                "button button--secondary button--circle button--inline-audio",
              class: _vm.playing ? "button--inline-audio-playing" : "",
              on: { click: _vm.togglePlay },
            },
            [_c("i", { class: _vm.playing ? "icon-pause" : "icon-play" })]
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }